import { createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";
import { DO_LOGIN } from "./type";
import { notification } from "antd";

const getLoggedUserAsync = async (params) => {
  try {
    const response = await apiClient.get(DO_LOGIN);
    if (response.data) {
      if (Number(response.data.code) === 0) {
        if (params.callback) {
          params.callback(response.data);
        }
      } else if (params.callbackFail) {
        params.callbackFail(response.data);
      }
    }
  } catch (error) {
    if (sessionStorage.getItem("reloadCount") == 2) {
      params.callbackFail({
        message: "システムにアクセスする権限がありません。",
        callback: () => window.location.reload()
      });
    } else {
      notification.error({
        message: "内部サーバーエラー",
      });
    }
  }
};

export const getLoggedUser = createAsyncThunk(DO_LOGIN, getLoggedUserAsync);
