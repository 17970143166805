import React, { useEffect, useState, useRef } from "react";
import {
  Layout,
  Row,
  Col,
  Popover,
  Button,
  Avatar,
  Badge,
  notification,
  Typography,
  Tooltip,
} from "antd";
import Select from "react-select";
import "./header.scss";
import { useDispatch, useSelector } from "react-redux";
import SignOutIcon from "@src/styles/icon/Sign_out.svg";
import NotifyIcon from "@src/styles/icon/notify.svg";

import { UserOutlined } from "@ant-design/icons";
import Notification from "./notification";
import { ROUTER_PAGE } from "../../constants/router-constants";
import {
  setOfficeIdSelected,
  selectorOfficeDetail,
} from "../../redux/modules/system/reducer";
import { checkRole } from "../../utils/checkRole";
import { clearCacheStorage } from "../../utils/dealCacheData";
import { ROLE_CONST } from "../../constants/role-constants";
import { setVisibleNotify } from "@src/redux/sideBar";
import { useHistory } from "react-router-dom";
import { getLogOutUrl } from "../../redux/services/config";
import { convertObjectToArray } from "../../utils/convertObjectToArray";
import { getNotify } from "../../redux/modules/notification/action";
import { setNotify } from "../../redux/modules/notification/reducer";
import { getLoggedUser } from "../../redux/modules/login/action";
import { setListOfficeOwnUser, selectorOfficeOwnUser } from "../../redux/auth";
import {
  ROUTERS_ONLY_OPTION_ALL,
  ROUTERS_DISABLE_OFFICE,
  ROUTERS_HAVE_OPTION_ALL,
  ROUTERS_NO_OPTION_ALL,
} from "../../constants/office-permission";
import { useLocation } from "react-router-dom";
import { selectorNotify } from "../../redux/modules/notification/reducer";
import { updateListReadNotify } from "../../redux/modules/notification/action";
import { unwrapResult } from "@reduxjs/toolkit";
import HomeIcon from "@src/styles/icon/home.svg";
import { setError } from "../../redux/error";

const { Header } = Layout;

function HeaderRoot() {
  const location = useLocation();
  let { visibleNotify } = useSelector((state) => state.sideBar);
  let [isLoading, setIsLoading] = useState(false);
  let { collapsed, tabChild, tabParent } = useSelector(
    (state) => state.sideBar
  );
  // let [expandSideBar, updateExpandSB] = useState(false);
  const { user, role } = useSelector((state) => state.auth);
  let [officeList, setOfficeList] = useState([]);
  let [loading, setLoading] = useState(false);
  const selectedOfficeId =
    useSelector(selectorOfficeDetail) || localStorage.getItem("officeSelected");
  const officeListHeader = useSelector(selectorOfficeOwnUser);
  const dispatch = useDispatch();
  let [officeId, setOfficeId] = useState(selectedOfficeId);
  const [disableOffice, setDisableOffice] = useState(false);
  const notifyDataArr = useSelector(selectorNotify) || {};
  const { numberUnread, notifyList } = notifyDataArr;
  const { Paragraph } = Typography;
  const selectOfficeRef = useRef(null);
  const intervalRef = useRef(null);
  const [reactAppEnv, setReactAppEnv] = useState("");
  const isSupperUser = checkRole(role, ROLE_CONST.P100);
  const [isChangeLinkMenu, setIsChangeLinkMenu] = useState(false);
  // let content = () => (
  //   <div>
  //     {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
  //     <div className="buttonClick" onClick={() => redirectLoginPage()}>
  //       ログアウト <LoginOutlined />
  //     </div>
  //   </div>
  // );
  // const toggleButton = () => {
  //   dispatch(collapsed(expandSideBar));
  //   updateExpandSB(!expandSideBar);
  // };

  const history = useHistory();
  const redirectLoginPage = () => {
    const urlNavigate = getLogOutUrl();
    clearCacheStorage();
    window.location.href = urlNavigate;
  };

  const handleVisibleChange = (visible) => {
    dispatch(setVisibleNotify(visible));
  };

  const hideNotify = () => {
    dispatch(setVisibleNotify(false));
  };

  const countNotifyText = () => {
    let count = countNotify();
    if (count <= 0) {
      return "0";
    }
    return count <= 9 ? "0" + count : "" + count;
  };

  const countNotify = () => {
    let count;
    count = numberUnread > 0 ? numberUnread : "";
    return count;
  };

  const readAllNotify = async () => {
    if (notifyList && notifyList.length) {
      try {
        setLoading(true);
        const callback = () => {
          setLoading(false);
        };
        const actionResult = await dispatch(updateListReadNotify({ callback }));
        const officeResult = unwrapResult(actionResult);
        await dispatch(
          getNotify({
            data: { offset: 0, limit: notifyList.length },
            isReadAll: true,
          })
        );
      } catch (error) {
        notification.error({
          message: error.message || "internet server error",
        });
        setLoading(false);
      }
    }
  };

  if (!user) return null;

  const filterOption = (option, inputValue) => {
    // console.log(option, inputValue, officeList)
    // const { label, value } = option;
    // const otherKey = officeList.filter(
    //   opt => opt.label === label
    // );
    // return otherKey.length > 0;
  };

  const handleChange = (officeSelected, type) => {
    let pathname = history.location.pathname.replace(/\/?\-?\d+/g, "");
    if (
      history.location.pathname.includes("office-management/dashboard-page")
    ) {
      sessionStorage.setItem("hasChangeProcessOfDasboard", true);
      // const dashboardObjId = {processPlaceId: "", officeId: ""};
      // setDashboardPageKeyStorage(dashboardObjId);
      // removeDashboardPageObjId(officeSelected.value);
    }
    history.push(`${pathname}/${officeSelected.value}`);
    localStorage.removeItem("officeSelected");
    localStorage.removeItem("tempOfficeSelected");
    localStorage.removeItem("warningEventFilter");
    localStorage.setItem("officeSelected", officeSelected.value);
    localStorage.setItem("tempOfficeSelected", officeSelected.value);
    setOfficeId(officeSelected.value);
    dispatch(setOfficeIdSelected(officeSelected.value));
  };

  useEffect(() => {
    let pathname = history.location.pathname;
    let userFactoryId = user.userFactoryId ? user.userFactoryId : 0;
    const officeIdParam = pathname.match(/\-?\d+/g)
      ? Number(pathname.match(/\-?\d+/g)[0])
      : null;
    if (pathname.includes("/null")) {
      pathname = pathname.slice(0, -5);
    }
    pathname = pathname.replace(/\/?\-?\d+/g, "");
    // if ()
    dispatch(
      getLoggedUser({
        callback: function (res) {
          const convertObOfficeToArray = convertObjectToArray(
            res.data.factoryMap
          );
          if (officeIdParam === null || officeIdParam === undefined) {
            if (
              (localStorage.getItem("tempOfficeSelected") ||
                localStorage.getItem("tempOfficeSelected") == 0) &&
              localStorage.getItem("tempOfficeSelected") != "null"
            ) {
              let factoryId =
                localStorage.getItem("tempOfficeSelected") &&
                localStorage.getItem("tempOfficeSelected") != 0
                  ? Number(localStorage.getItem("tempOfficeSelected"))
                  : localStorage.getItem(pathname)
                  ? localStorage.getItem(pathname)
                  : 0;
              setOfficeId(factoryId);
              dispatch(setOfficeIdSelected(factoryId));
              history.push(`${pathname}/${factoryId}`);
            } else {
              userFactoryId = !isSupperUser
                ? convertObOfficeToArray[0].value
                : -1;
              setOfficeId(userFactoryId);
              localStorage.setItem("tempOfficeSelected", userFactoryId);
              localStorage.setItem("officeSelected", userFactoryId);
              dispatch(setOfficeIdSelected(userFactoryId));
              history.push(`${pathname}/${userFactoryId}`);
            }
          } else {
            if (
              ((officeIdParam == -1 || officeIdParam == 0) &&
                ROUTERS_NO_OPTION_ALL.indexOf(pathname) != -1) ||
              !convertObOfficeToArray.length
            ) {
              notification.error({
                message:
                  "本機能を利用するために特定の事業所を選択してください。",
              });
              setOfficeId(0);
            } else {
              // setOfficeId(userFactoryId);
              // localStorage.setItem("tempOfficeSelected", userFactoryId);
              // localStorage.setItem("officeSelected", userFactoryId);
              // dispatch(setOfficeIdSelected(userFactoryId));
            }
          }
        },
      })
    );
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("/null")) {
      handleChangeRouter(location.pathname);
    }
  }, [selectedOfficeId, location]);

  useEffect(() => {
    if (notifyList && !notifyList.length) {
      dispatch(setNotify({}));
      dispatch(getNotify({ data: { offset: 0, limit: 100 } }));
    }
  }, [user, history]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const callback = () => {
        if (document.querySelector("#scrollableDiv")) {
          document.querySelector("#scrollableDiv").scrollTop = 0;
        }
      };
      // dispatch(setNotify());
      dispatch(
        getNotify({
          data: { offset: 0, limit: 100 },
          isGetNotifyInterval: true,
          callback,
        })
      );
    }, 60000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  history.listen(() => {
    selectOfficeRef.current = null;
  });

  useEffect(() => {
    setOfficeList(officeListHeader);
  }, [officeListHeader]);

  const handleChangeRouter = (pathname) => {
    const officeIdParam = pathname.match(/\-?\d+/g)
      ? Number(pathname.match(/\-?\d+/g)[0])
      : null;
    pathname = pathname.replace(/\/?\-?\d+/g, "");
    let convertObOfficeToArray = [];
    let factoryId;
    // if (!history.location.pathname.includes("office-management/dashboard-page") && getDashboardPageKeyStorage()) {
    //   sessionStorage.removeItem("hasChangeProcessOfDasboard")
    // }
    if (ROUTERS_ONLY_OPTION_ALL.indexOf(pathname) !== -1) {
      dispatch(
        getLoggedUser({
          callback: function (res) {
            convertObOfficeToArray = convertObjectToArray(res.data.factoryMap);
            convertObOfficeToArray.unshift({
              value: -1,
              label: "全事業所",
            });
            dispatch(setListOfficeOwnUser(convertObOfficeToArray));
            localStorage.setItem("officeSelected", officeIdParam);
            localStorage.setItem("tempOfficeSelected", officeIdParam);
            dispatch(setOfficeIdSelected(officeIdParam));
            setOfficeId(-1);
            setDisableOffice(true);
          },
        })
      );
      // localStorage.setItem("officeSelected", -1);
    } else if (ROUTERS_HAVE_OPTION_ALL.indexOf(pathname) !== -1) {
      setIsLoading(true);
      dispatch(
        getLoggedUser({
          callback: function (res) {
            convertObOfficeToArray = convertObjectToArray(res.data.factoryMap);
            if (isSupperUser) {
              convertObOfficeToArray.unshift({
                value: -1,
                label: "全事業所",
              });
            }
            dispatch(setListOfficeOwnUser(convertObOfficeToArray));
            if (selectedOfficeId == 0 && officeIdParam != 0) {
              factoryId = -1;
            } else {
              factoryId =
                officeIdParam !== undefined
                  ? officeIdParam
                  : user.userFactoryId;
              if (factoryId == -1 && !isSupperUser) {
                factoryId = 0;
              }
            }

            dispatch(setOfficeIdSelected(factoryId));
            setOfficeId(factoryId);
            localStorage.setItem("officeSelected", factoryId);
            localStorage.setItem("tempOfficeSelected", factoryId);

            // dispatch(setOfficeIdSelected(factoryId));
            setDisableOffice(false);
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          },
          callbackFail: function (data) {
            dispatch(
              setError({
                message: data.message,
              })
            );
            history.push("/error");
            return;
          },
        })
      );
    } else if (ROUTERS_NO_OPTION_ALL.indexOf(pathname) !== -1) {
      dispatch(
        getLoggedUser({
          callback: function (res) {
            convertObOfficeToArray = convertObjectToArray(res.data.factoryMap);
            dispatch(setListOfficeOwnUser(convertObOfficeToArray));
            if (
              officeIdParam == -1 ||
              officeIdParam == 0 ||
              (localStorage.getItem("tempOfficeSelected") == 0 &&
                !officeIdParam)
            ) {
              // if (selectOfficeRef.current != 0 && !convertObOfficeToArray.length) {
              //   notification.error({
              //     message:
              //       "本機能を利用するために特定の事業所を選択してください。",
              //   });
              // }
              // factoryId = 0;
              // if (sessionStorage.getItem(pathname)) {
              //   factoryId = sessionStorage.getItem(pathname);
              // } else if (user.userFactoryId) {
              //   factoryId = user.userFactoryId;
              // } else if (convertObOfficeToArray.length) {
              //   factoryId = convertObOfficeToArray[0];
              // }
              // history.push(`${pathname}/${factoryId}`);
              // selectOfficeRef.current = 0;
              // localStorage.setItem("tempOfficeSelected", factoryId);
              // setOfficeId(factoryId);
              // setDisableOffice(false);
              // dispatch(setOfficeIdSelected(factoryId));
              // return;
            } else {
              factoryId =
                officeIdParam !== undefined
                  ? officeIdParam
                  : user.userFactoryId;
              // if (history.location.pathname.includes("office-management/dashboard-page") && getDashboardPageObjId(factoryId)) {
              // const dashboardSavedStorerage = getDashboardPageObjId(factoryId);
              // const dashboardObjId = {...dashboardSavedStorerage, officeId: officeIdParam}
              // if (officeIdParam != localStorage.getItem("officeSelected") && sessionStorage.getItem("hasChangeProcessOfDasboard")) {
              //   dashboardObjId.processPlaceId = "";
              // }
              // sessionStorage.setItem("hasChangeProcessOfDasboard", true);
              // setDashboardPageKeyStorage(dashboardObjId);

              // }
              // if (localStorage.getItem("officeSelected")) {
              //   dispatch(setOfficeIdSelected(factoryId));
              // }
              localStorage.setItem("officeSelected", factoryId);
              localStorage.setItem(pathname, factoryId);
              localStorage.setItem("tempOfficeSelected", factoryId);
              setOfficeId(factoryId);
              setDisableOffice(false);
              dispatch(setOfficeIdSelected(factoryId));
              // dispatch(setOfficeIdSelected(factoryId));
            }
            dispatch(setListOfficeOwnUser(convertObOfficeToArray));
          },
          callbackFail: function (data) {
            dispatch(
              setError({
                message: data.message,
              })
            );
            history.push("/error");
            return;
          },
        })
      );
    } else if (ROUTERS_DISABLE_OFFICE.indexOf(pathname) !== -1) {
      dispatch(
        getLoggedUser({
          callback: function (res) {
            convertObOfficeToArray = convertObjectToArray(res.data.factoryMap);
            // convertObOfficeToArray.unshift({
            //   id_option: -1,
            //   name_option: "全事業所",
            // });
            dispatch(setListOfficeOwnUser(convertObOfficeToArray));
            if (selectedOfficeId == 0) {
              factoryId = -1;
            } else {
              factoryId =
                officeIdParam !== undefined
                  ? officeIdParam
                  : user.userFactoryId;
            }
            dispatch(setOfficeIdSelected(factoryId));
            localStorage.setItem("officeSelected", factoryId);
            localStorage.setItem("tempOfficeSelected", factoryId);
            setOfficeId(factoryId);
            setDisableOffice(true);
          },
          callbackFail: function (data) {
            dispatch(
              setError({
                message: data.message,
              })
            );
            history.push("/error");
            return;
          },
        })
      );
    }
  };

  const renderOfficeHeader = () => {
    return (
      <Select
        className="basic-single headerSelect"
        classNamePrefix="select"
        styles={customStyles}
        value={officeList.find((office) => office.value == officeId) || null}
        onChange={handleChange}
        options={officeList}
        placeholder=""
        isDisabled={disableOffice}
        noOptionsMessage={() => "データがありません"}
        // filterOption={filterOption}
      />
    );
  };

  useEffect(() => {
    setReactAppEnv(process.env.REACT_APP_ENV);
  }, [process.env.REACT_APP_ENV]);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      boxShadow: state.isFocused ? null : null,
      width: 168,
      height: 25,
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      hyphens: "auto",
      width: 168,
      textAlign: "left",
      wordWrap: "break-word",
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
      hyphens: "auto",
      wordWrap: "break-word",
      "::-webkit-scrollbar": {
        width: "5px",
        backgroundColor: "#f5f5f5",
        height: "6px",
        border: "1px solid #d5d5d5",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#bfbfbf",
        borderRadius: "10px",
      },
      "::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        backgroundColor: "#f5f5f5",
        borderRadius: "10px",
      },
      scrollbarWidth: "thin",
      scrollbarColor: "#bfbfbf #d5d5d5",
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #dadada",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#000000",
      fontWeight: 100,
      cursor: "pointer",
      fontSize: "14px",
      backgroundColor: state.isSelected ? "#e6f7ff" : "white",
    }),
  };

  const renderBadgeNotify = () => {
    return (
      <Popover
        id="scrollableNotify"
        trigger="click"
        content={
          <Notification hideNotify={hideNotify} loadingPopover={loading} />
        }
        visible={visibleNotify}
        overlayClassName={"overlay-popover-notification"}
        onVisibleChange={(visible) => handleVisibleChange(visible)}
        title={() => (
          <span style={{ fontSize: "12px", color: "#003D8D" }}>
            あなたがまだ読んでいない
            <span style={{ color: "#f27718" }}>{countNotifyText()}</span>
            のメールがあります。
            <Button type="link" onClick={() => readAllNotify()}>
              すべて既読にする
            </Button>
          </span>
        )}
      >
        <Badge className="customNotify" count={countNotify()}>
          <img
            className="notify-icon"
            style={{ width: "24px", height: "24px", fill: "#fff" }}
            src={NotifyIcon}
            alt="notify"
          />
        </Badge>
      </Popover>
    );
  };
  const handleRedirectHomePage = () => {
    history.push(ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE);
  };

  return (
    <Header className="site-layout-background">
      <Row className="rowNotMargin">
        <Col
          className="name-header-office"
          xl={17}
          lg={16}
          md={12}
          xs={12}
          sm={12}
        >
          <Row justify="start" align="top" className="header-left">
            {/*<Col xl={1} lg={1} md={1} xs={1} sm={1} style={{minWidth: "40px"}}>*/}
            {/*   <img className="dashboard-icon"  src={DashboardIcon} alt="dashboard" />*/}
            {/*</Col>*/}
            {/*<Col className="dasboard-header" xl={6} lg={8} md={10} xs={8} sm={8}>*/}
            {/*  <Tooltip placement="topLeft" title="全事業所のダッシュボード">*/}
            {/*    <Paragraph ellipsis>*/}
            {/*      <span className="title-dashboard">全事業所のダッシュボード</span>*/}
            {/*    </Paragraph>*/}
            {/*  </Tooltip>*/}
            {/*</Col>*/}
            {/*<Col className="devide" xl={1} lg={1} md={1} xs={1} sm={1}>*/}
            {/*  <span className="line"></span>*/}
            {/*</Col>*/}
            <Col span={24} className="bigTitlePage">
              {/* <span>{ROUTER_PAGE_MAPPING[history.location.pathname]}</span> */}
              <Row style={{ width: "100%", flexWrap: "nowrap" }}>
                <Col className="label-select-office">
                  <span>事業所を選択</span>
                </Col>
                <Col
                  // flex="200px"
                  style={{ paddingTop: "4px", paddingRight: "10px" }}
                  className={`${
                    reactAppEnv == "development"
                      ? "select-office-dev"
                      : "select-office-prod"
                  } select-border-color-header `}
                >
                  {renderOfficeHeader()}
                </Col>
                {/* <Paragraph className="name-page" ellipsis={{ row: 1 }}>
                  {ROUTER_PAGE_MAPPING[history.location.pathname.replace(/\/?\-?\d+/g, "")]}
                </Paragraph> */}
              </Row>
            </Col>
            {/* <Col xl={1} lg={1} md={1} xs={0} sm={0}></Col> */}
            {/* <Col
              flex="200px"
              style={{ paddingTop: '4px', paddingRight: '10px' }}
              className={`${reactAppEnv == "development"
                ? "select-office-dev"
                : "select-office-prod"
                } select-border-color-header `}
            >
              {renderOfficeHeader()}
            </Col> */}
          </Row>
        </Col>
        <Col xl={7} lg={8} md={12} xs={12} sm={12} className="infoUser">
          <Row className="rowNotMargin">
            <div className="notify-radio-group">{renderBadgeNotify()}</div>
            <Avatar
              style={{
                marginRight: "6px",
                cursor: "pointer",
                width: "40px",
                height: "40px",
                display: "block",
                marginTop: "3px",
              }}
              icon={<UserOutlined />}
            />
            <Tooltip placement="topLeft" title={user.userName}>
              <Paragraph
                style={{ maxWidth: "100px", marginRight: "19px" }}
                ellipsis
              >
                <span className="user-name"> {user.userName}</span>
              </Paragraph>
            </Tooltip>
            <Tooltip placement="topLeft" title="ログアウト">
              <img
                className="signout-icon"
                src={SignOutIcon}
                alt="dashboard"
                style={{fill: "#fff"}}
                onClick={() => redirectLoginPage()}
              />
            </Tooltip>
          </Row>
        </Col>
      </Row>
    </Header>
  );
}

export default React.memo(HeaderRoot);
